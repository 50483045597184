/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    '4HRK4DMDmYfif6DJ5VTcmJ.png': { uri: '/images/4HRK4DMDmYfif6DJ5VTcmJ.png' },
'rTQcC3uTYgJGjZpdfzmTGF.jpg': { uri: '/images/rTQcC3uTYgJGjZpdfzmTGF.jpg' },
'5mAyPgMmTTETBqqA5rvN8c.png': { uri: '/images/5mAyPgMmTTETBqqA5rvN8c.png' },
'9d68xGjJ4aerPezLkWYEpU.png': { uri: '/images/9d68xGjJ4aerPezLkWYEpU.png' },
'6SQmXZ8TFwpgGmgo7gS3Ri.png': { uri: '/images/6SQmXZ8TFwpgGmgo7gS3Ri.png' },
'nXL5TYMAUQmyxChjXH11of.png': { uri: '/images/nXL5TYMAUQmyxChjXH11of.png' },
'vukMASQ9ds1GnHHkPo5Dso.png': { uri: '/images/vukMASQ9ds1GnHHkPo5Dso.png' },
'84cGY7Q7qDUkuMsQ3kWx5g.png': { uri: '/images/84cGY7Q7qDUkuMsQ3kWx5g.png' },
'tvuENcmeVWNG4Q2cLcHLok.png': { uri: '/images/tvuENcmeVWNG4Q2cLcHLok.png' },
'6dKgBZuAVpQm7C3nyfsCfZ.png': { uri: '/images/6dKgBZuAVpQm7C3nyfsCfZ.png' },
'8QHBgV6FepTKy8DxUyjGxt.png': { uri: '/images/8QHBgV6FepTKy8DxUyjGxt.png' },
'm8giEDD6EJontriihHg2Vj.png': { uri: '/images/m8giEDD6EJontriihHg2Vj.png' },
'fzdh8dv6fveaSG9YzYUJ6i.png': { uri: '/images/fzdh8dv6fveaSG9YzYUJ6i.png' },
'8FRzXhUPUQEiWX1dNu519a.png': { uri: '/images/8FRzXhUPUQEiWX1dNu519a.png' },
'fhWxmPQu8YLAqWogbbC1b1.png': { uri: '/images/fhWxmPQu8YLAqWogbbC1b1.png' }
}

export default imageStaticSourcesByFileName
